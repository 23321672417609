<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>New Book</v-card-title>
        <v-card-text>
          <template>
            <FormInput
              :value="form['translatableName']"
              label="Translatable Name"
              :rules="nameRules"
              @input="form['translatableName'] = $event"
            />
          </template>
          <!-- <template>
            <FormTextArea
              :value="form['translatableSummary']"
              label="Translatable Summary"
              :rules="summaryRules"
              @input="form['translatableSummary'] = $event"
            />
          </template> -->
          <template>
            <FormInput
              :value="form['key']"
              label="Key"
              :rules="keyRules"
              @input="form['key'] = $event"
            />
          </template>
          <template>
            <FormSelect label="Subject" :data="subject" :value="form['subject']" @input="form['subject'] = $event"></FormSelect>
          </template>
          <template>
            <FormImage
              :value="form['imageEN']"
              label="Image EN"
              @input="form['imageEN'] = $event"
            />
          </template>
          <template>
            <FormImage
              :value="form['imageMS']"
              label="Image MS"
              @input="form['imageMS'] = $event"
            />
          </template>
          <template>
            <FormImage
              :value="form['imageZH']"
              label="Image ZH"
              @input="form['imageZH'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['sort']"
              label="Sort"
              :rules="sortRules"
              type="number"
              @input="form['sort'] = $event"
            />
          </template>
          <!-- <template>
            <FormInput
              :value="form['price']"
              label="Price"
              type="number"
              @input="form['price'] = $event"
            />
          </template> -->
          <!-- <template>
            <FormSelect label="Currency Type" :data="currencyType" :value="form['currencyType']" @input="form['currencyType'] = $event"></FormSelect>
          </template> -->
          <template>
            <FormInput
              :value="form['author']"
              label="Author"
              :rules="authorRules"
              @input="form['author'] = $event"
            />
          </template>
          <template>
            <FormRadio
              :value="form['isPublished']"
              label="Published"
              @input="form['isPublished'] = $event"
            />
          </template>
          <template>
            <FormSelect label="Book Type" :data="bookType" :value="form['type']" @input="form['type'] = $event"></FormSelect>
          </template>
          <template>
            <FormDate
              :callbackSelect="selectDate"
              formKey="dateStart"
              name="Start Date"
              :value="form.dateStart"
            />
          </template>
          <template>
            <FormTime
              :callbackSelect="selectTime"
              formKey="timeStart"
              name="Start Time"
              :value="form.timeStart"
            />
          </template>
          <template>
            <FormDate
              :callbackSelect="selectDate"
              formKey="dateEnd"
              name="End Date"
              :value="form.dateEnd"
            />
          </template>
          <template>
            <FormTime
              :callbackSelect="selectTime"
              formKey="timeEnd"
              name="End Time"
              :value="form.timeEnd"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/Book/FormInput";
import FormSelect from "@/components/Moderator/Book/FormSelect";
import FormRadio from "@/components/Moderator/Book/FormRadio";
import FormDate from "@/components/Moderator/Book/FormDate";
import FormTime from "@/components/Moderator/Book/FormTime";
import FormImage from "@/components/Moderator/Book/FormImage";
// import FormTextArea from "@/components/Moderator/Book/FormTextArea";

import moment from "moment";

import axios from "axios";
import store from "../../../store";
import { mapState } from "vuex";
export default {
  props: ["data", "callbackSuccess"],
  components: {
    FormInput,
    FormSelect,
    FormRadio,
    FormDate,
    FormTime,
    FormImage,
    // FormTextArea
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    // currencyType:[{name:'MYR',key:'MYR'},{name:'LKR',key:'LKR'}],
    bookType:[{name:'Free',key:'free'},{name:'Premium',key:'premium'},{name:'Event',key:'event'},],
    subject:[{name:'English',key:'English'},{name:'Maths',key:'Maths'}],
    //BOC:[form]
    form: {
      translatableName: '{"en":"New Book Title","ms":"Tajuk Buku Baru","zh":"新书的书名"}',
      translatableSummary: '{"en":"","ms":"","zh":""}',
      key: null,
      subject:'English',
      imageEN: null,
      imageMS: null,
      imageZH: null,
      sort: 1,
      price: 0,
      currencyType:'MYR',
      isPublished: false,
      type: "free",
      author: "Mrjian",
      dateStart: null,
      dateEnd: null,
      timestampStart: null,
      timestampEnd: null,
      timeStart: null,
      timeEnd: null,
    },
    fields: [],
    rules: {},
    sortRules: [],
    keyRules: [],
    authorRules: [],
    nameRules: [],
    summaryRules:[],
    mobile: null,
    code: null,
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.game}/api/v1/en/moderator/book/add`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    selectDate(date, key) {
      if (key == "dateStart") {
        this.form.dateStart = date;
      } else {
        this.form.dateEnd = date;
      }
    },
    selectTime(time, key) {
      if (key == "timeStart") {
        this.form.timeStart = time;
      } else {
        this.form.timeEnd = time;
      }
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      this.nameRules = [(v) => !!v || "Translatable name is required"];
      this.sortRules = [(v) => !!v || "Sort is required"];
      this.keyRules = [(v) => !!v || "Book key is required"];
      this.authorRules = [(v) => !!v || "Book author is required"];
      // this.summaryRules = [(v) => !!v || "Book summary is required"];

      if (this.$refs.form.validate()) {
        this.form.timestampStart = moment(
          this.form.dateStart + " " + this.form.timeStart
        ).toISOString();
        this.form.timestampEnd = moment(
          this.form.dateEnd + " " + this.form.timeEnd
        ).toISOString();
        if (this.form.timestampStart > this.form.timestampEnd) {
          this.api.callbackError(
            "The book start date and time should be less than the event end date and time."
          );
        } else {
          this.submit();
        }
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    async submit() {
      // this.api.params = this.$_.clone(this.form);
      // this.$api.fetch(this.api);
      this.api.isLoading = true;
      const formData = new FormData();
      const token = "Bearer " + store.state.auth.data.Session.token;
      const config = {
        headers: { Authorization: token },
      };
      formData.append(
        "translatableName",
        this.form.translatableName
      );
      formData.append(
        "translatableSummary",
        this.form.translatableSummary
      );
      formData.append("key", this.form.key);
      formData.append("subject", this.form.subject);
      formData.append("imageEN", this.form.imageEN);
      formData.append("imageMS", this.form.imageMS);
      formData.append("imageZH", this.form.imageZH);
      formData.append("sort", this.form.sort);
      formData.append("price", this.form.price);
      formData.append("currencyType", this.form.currencyType);
      formData.append("isPublished", this.form.isPublished);
      formData.append("type", this.form.type);
      formData.append("author", this.form.author);
      formData.append("timestampStart", this.form.timestampStart);
      formData.append("timestampEnd", this.form.timestampEnd);
      try {
    // Make the API request
    const response = await axios.post(
      this.$api.servers.game + "/api/v1/en/moderator/book/add",
      formData,
      config
    );

    this.api.isLoading = false;
    this.callbackSuccess(response.data);
  } catch (error) {
    this.api.isLoading = false;
    this.api.isLoading = false;
    this.api.isError = true;
    this.api.error = error;
  }
    },
    //EOC
  },
};
</script>

<style>
</style>